<template>
  <section class="e_table">
    <header class="table_header">
      <div class="header_left">
        <slot name="header_left">
          <span v-if="title">{{ title }},</span>
          共{{ total || data.length }}条数据
          <span class="data_tips">无数据权限时为乱序</span>
        </slot>
      </div>
      <div class="header_right">
        <slot name="header_right"></slot>
        <el-button v-for="(item, index) in headerBtns" :key="index" v-bind="item">{{ item.text }}</el-button>
      </div>
    </header>
    <el-table 
      :key="tableKey"
      highlight-current-row 
      v-bind="{...$attrs, ...$props}" 
      :data="getTableData"
    >
      <el-table-column v-for="(col, ind) in column" :key="ind" v-bind="col">
        <template #default="scoped" v-if="col.component">
          <component :is="col.component" :key="scoped.$index" :index="scoped.$index" :col="{...scoped.column, ...col}" :row="scoped.row"></component>
        </template>
      </el-table-column>
    </el-table>
    <footer class="table_footer">
      <el-pagination
        v-if="isPaginated"
        background
        layout="prev, pager, next"
        :total="total || data.length"
        :page-size="pageSize"
        v-model:current-page="page"
      >
      </el-pagination>
    </footer>
  </section>
</template>

<script>
  import {defineComponent} from 'vue'
  export default {
    props: {
      // 标题
      title: {
        type: String,
        default: ''
      },
      // 总数
      total: {
        type: Number,
        default: 1
      },
      // 数据
      data: {
        type: Array,
        default: ()=>[]
      },
      // 表头
      column: {
        type: Array,
        default:()=>[
          {
            label: '名称',
            prop: 'name',
          },
          {
            label: '地区',
            prop: 'province',
            // formatter: (row, column, cellValue, index)=>{
            //   return row['city']
            // },
            component: defineComponent({
              render() {
                return <a href="http://www.baidu.com">百度</a>
              }
            })
          },
        ]
      },
      // 右上角按钮
      headerBtns: {
        type: Array,
        default: ()=>[]
      },

      // 总量
      total: {
        type: Number,
        default: 10
      },
      // 页码大小
      pageSize: {
        type: Number,
        default: 10
      },
      // 是否分页
      isPaginated: {
        type: Boolean,
        default: false
      },
      // 是否前端分页
      isPaginatedByWeb: {
        type: Boolean,
        default: true
      },

      // 高度
      height: [Number, String]
    },
    methods: {
     
    },
    data() {
      return {
        page: 1,  // 当前页码
        tableKey: Math.random(),
      }
    },
    computed: {
      getTableData(){
        if (this.isPaginated && this.isPaginatedByWeb) {
          return this.data.slice((this.page-1)*this.pageSize, this.page*this.pageSize)
        }
        return this.data
      }
    },
    watch: {
      height(){
        this.tableKey=Math.random()
      }
    }
  }
</script>

<style lang="scss" scoped>
.e_table{
  .table_header{
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;

    .header_left{
      .data_tips{
        color: red;
        margin-left: 10px;
        // font-weight: bold;
      }
    }

    .header_right{
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .table_footer{
    margin-top: 10px;
    text-align: right;
  }

  ::v-deep .current-row td{
    background-color: #fbec88 !important;
  }
}
</style>>
