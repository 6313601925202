<template>
  <section class="convertible_table">
    <e-table 
      ref="table"
      :column="column" 
      :data="data" 
      :headerBtns="headerBtns" 
      :total="allData.length"
      stripe
      lazy
      v-bind="{...$attrs, ...$props}"
      @sort-change="sortChange"
    >
      <template v-slot:header_right>
        <el-input class="search" v-model.lazy="searchText" placeholder="输入代码或者名称搜索" size="small" clearable prefix-icon="el-icon-search"></el-input>
      </template>
    </e-table>
  </section>
</template>

<script>
import eTable from '@/components/table/index.vue'
import { defineComponent, ref, reactive } from 'vue'
import utils from '@/utils'
const { addPrefix } = utils
export default defineComponent({
  components: {
    'e-table': eTable
  },
  props: {
    // 是否显示选择
    canSelect: {
      type: Boolean,
      default: true
    },
    height: {
      type: [String, Number],
      default: '0'
    }
  },
  data(){
    return {
      isExtend: ref(false),  // 是否拓展表格
      headerBtns: reactive([
        // {
        //   size: 'small',
        //   text: '简化/拓展',
        //   onclick:()=>{
        //     this.isExtend = !this.isExtend
        //   }
        // },
        {
          size: 'small',
          text: '刷新',
          onclick:()=>{
            this.getData()
          }
        },
      ]),
      searchText: ref(''), // 搜索文本
      // data: reactive([]), // 表格数据
      allData: reactive([]),  // 所有表格数据备份
      levels: reactive({  // 转债等级
        'B-': 1,
        'B': 2,
        'B+': 3,
        'BB-': 4,
        'BB': 5,
        'BB+': 6,
        'BBB-': 7,
        'BBB': 8,
        'BBB+': 9,
        'A-': 10,
        'A': 11,
        'A+': 12,
        'AA-': 13,
        'AA': 14,
        'AA+': 15,
        'AAA': 16
      }),
      maxScrollTop: ref(0),  // 最大滚动高度
      page: ref(1),  // 页码
      size: ref(30), // 单页大小
      timer: ref(null), // 定时器
    }
  },
  computed: {
    column(){
      let col=[]
      if (this.canSelect) {
        col.push({
          type: 'selection',
          // fixed: true
        })
      }
      col.push(...[
        {
          type: 'index',
          label: '序号',
          // fixed: true
        },
        {
          prop: 'bond_id',
          label: '转债代码',
          width: 80,
          // fixed: true,
          component: defineComponent({
            props: ['row', 'col'],
            render(){
              let code=this.row[this.col.property]
              if (code) {
                return (<a class="other_link" target="_blank" href={`http://xueqiu.com/S/${addPrefix(code)}`}>{this.row[this.col.property]}</a>)
              } else {
                return '-'
              }
            }
          })
        },
        {
          prop: 'bond_nm',
          label: '转债名称',
          width: 80,
          // fixed: true,
          component: defineComponent({
            props: ['row', 'col'],
            render(){
              let code=this.row['bond_id']
              if (code) {
                return (<a class="other_link" target="_blank" href={`http://xueqiu.com/S/${addPrefix(code)}`}>{this.row[this.col.property]}</a>)
              } else {
                return '-'
              }
            }
          })
        },
        {
          prop: 'price',
          label: '现价',
          width: 80,
        },
        {
          prop: 'stock_nm',
          label: '正股名称',
          width: 80,
          // fixed: true,
          component: defineComponent({
            props: ['row', 'col'],
            render(){
              let code=this.row['stock_id']
              if (code) {
                return (<a class="other_link" target="_blank" href={`http://xueqiu.com/S/${addPrefix(code)}`}>{this.row[this.col.property]}</a>)
              } else {
                return '-'
              }
            }
          })
        },
        {
          prop: 'orig_iss_amt',
          label: '规模(亿)',
          // component: defineComponent({
          //   props: ['row', 'col'],
          //   render(){
          //     return (
          //       <div innerHTML={this.row[this.col.property]}></div>
          //     )
          //   }
          // })
        },
        {
          prop: 'curr_iss_amt',
          label: '剩余规模(亿)',
        },
        {
          prop: 'convert_dt',
          label: '转股起始日',
          width: 130,
        },
        {
          prop: 'convert_price',
          label: '转股价',
          width: 80,
        },
        {
          prop: 'redeem_price_ratio',
          label: '强赎触发比',
        },
        {
          prop: 'force_redeem_price',
          label: '强赎触发价'
        },
        {
          prop: 'sprice',
          label: '正股价',
          // formatter:(row, column, cellValue, index)=>{
          //   return row[column.property]  && (row[column.property]) + '%' || '-'
          // }
        },
        {
          prop: 'real_force_redeem_price',
          label: '强赎价',
        },

        {
          prop: 'redeem_icon',
          label: '强赎天计数',
          width: 100,
          component: defineComponent({
            props: ['row', 'col'],
            computed: {
              isRedeem(){
                return this.row[this.col.property]=='R'
              },
              text(){
                return this.isRedeem && '公告要强赎' || '公告不强赎'
              },
            },
            // created(){
            //   console.log(this.row, this.col)
            // },
            render(){
              return (
                
                <el-tooltip
                  effect="dark" 
                  content={this.row['force_redeem'] || this.text}
                  placement="left"
                >
                  <span>{this.text}</span>
                </el-tooltip>
              )
            }
          })
        },
        {
          prop: 'redeem_tc',
          label: '强赎条款',
          width: 360
          // formatter:(row, column, cellValue, index)=>{
          //   return (row[column.property]) + '%'
          // }
        },
      ])
      return col

    },
    data(){
      
      return this.allData.filter(row=>{
        if (this.searchText) {
          return row['bond_id'].includes(this.searchText) || row['bond_nm'].includes(this.searchText) || row['stock_nm'].includes(this.searchText)
        }
        return true
      }).slice(0, this.page * this.size)
    }
  },
  methods: {
    init(){
      this.getData()
      this.scrollFn()
    },

    // 获取计出数据
    async getData(){
      let res = await this.$api.getRedeem()
      if (res) {
        // console.log(res)
        // this.data=res.data.slice(0, 30)
        this.allData=res.rows.map(it=>it.cell).filter(it=>it.btype=='C')
      }
    },
    // 排序
    sortChange({ column, prop, order }){
      // console.log(order)
      this.allData=this.allData.sort((a, b)=>{
        if (order==='ascending') {
          // 升序
          if (prop === '评级') return this.levels[a[prop]] - this.levels[b[prop]]
          return a[prop] - b[prop]
        } else {
          // 降序
          if (prop === '评级') return this.levels[b[prop]] - this.levels[a[prop]]
          return b[prop] - a[prop]
        }
      })
    },

    // // 过滤筛选
    // filterFn(){
    //   this.data = this.allData.filter(row=>{
    //     if (this.searchText) {
    //       return row['转债代码'].includes(this.searchText) || row['债券简称'].includes(this.searchText) || row['正股简称'].includes(this.searchText)
    //     }
    //     return true
    //   })
    // },

    // 滚动
    scrollFn() {
      this.$nextTick(() => {
        document.querySelector('.el-table__body-wrapper').addEventListener('scroll', (e) => {
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            const {
              target
            } = e
            const canScrollHeight = target.scrollHeight - target.offsetHeight
            // console.log(e, canScrollHeight, target.scrollTop)
            if (target.scrollTop > this.maxScrollTop) {
              this.maxScrollTop = target.scrollTop
              if (target.scrollTop / canScrollHeight >= 0.8) this.page++
            }

          }, 50);
        })
      })
    }
  },
  created() {
    // console.log(8989)
    // this.getData()
    // this.scrollFn()
  },
  watch: {
    height(){
      this.init()
    }
  }
})
</script>

<style lang="scss" scoped>
.convertible_table{
  padding: 10px 10px 0 10px;
  .search{
    margin-right: 10px;
  }
  ::v-deep a{
    color: #0000ee;
  }
}
</style>
