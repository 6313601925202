<template>
	<div>
		<eTable 
			:height="tableHeight" 
			:canSelect="false"
		></eTable>
	</div>
</template>

<script>
import eTable from '@/components/redeemTable/index.vue'
export default {
	name: "redeem",
	components: {
		eTable
	},
	data() {
		return {
			tableHeight: 0,
			tableKey: Math.random()
		};
	},
	created() {
		// this.getData();
	},
	methods: {
		getHeight(){
			this.tableHeight=document.querySelector('.content-box .content').offsetHeight - 60
		},
		onResize(){
			window.addEventListener('resize', ()=>{
				this.getHeight()
			})
		}
	},
	mounted() {
		this.onResize()
		this.getHeight()
		this.tableKey=Math.random()
	}
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>
